<template>
  <ys-dialog
      :visible.sync="innerVisible"
      @update:visible="updateVisible"
      title="添加营养师信息"
  >
    <div class="padding padding-large">
      <div class="padding-vertical">
        <span>营养师头像：</span>
        <ys-image-picker
            class="image-avatar"
            :image.sync="avatar"
        ></ys-image-picker>
      </div>
      <div class="padding-vertical">
        <span>营养师姓名：</span>
        <ys-input
            v-model="name"
            placeholder="请输入营养师姓名"
            :maxlength="20"
        ></ys-input>
      </div>
    </div>
    <div
        slot="footer"
    >
      <ys-button type="secondary" lighting @click="clickCancel">取消</ys-button>
      <ys-button class="margin-left" lighting @click="clickConfirm">确定</ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import httpapi from "@/assets/javascript/httpapi";
import YsInput from "@/components/wedigets/YsInput";
import YsButton from "@/components/wedigets/YsButton";
import YsImagePicker from "@/components/wedigets/YsImagePicker";

export default {
  name: "DietitianAddDialog",
  mixins: [httpapi],
  components: {YsButton, YsInput, YsImagePicker, YsDialog},
  props: {
    accountId: Number,
    accountName: String,
    accountAvatar: Object,
    visible: Boolean,
  },
  data() {
    return {
      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 营养师信息
       */
      name: null,
      avatar: null,

      /**
       * 显示弹窗
       */
      innerVisible: false,
    }
  },
  watch: {
    visible: {
      handler(visible) {
        this.innerVisible = visible;
        this.name = this.accountName;
        this.avatar = this.accountAvatar;
      },
      immediate: true,
    },
    accountId: {
      handler() {
        this.loadingCode++;
      },
      immediate: true,
    }
  },
  methods: {
    updateVisible() {
      this.$emit('update:visible', this.innerVisible);
    },
    clickCancel() {
      this.innerVisible = false;
      this.updateVisible();
    },
    clickConfirm() {
      if (!this.accountId) {
        this.$message.error('系统错误');
        return;
      }
      if (!this.avatar) {
        this.$message.warning('请上传营养师照片');
        return;
      }
      if (!this.name) {
        this.$message.warning('请输入营养师姓名');
        return;
      }
      this.addDietitian();
    },
    addDietitian() {
      if (this.busyLoadingCode === this.loadingCode) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqPost({
        path: `/dietitian/${this.accountId}/${encodeURIComponent(this.name)}/${this.avatar ? this.avatar.id : ''}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dietitian = res.data;
            this.$emit('confirm', dietitian);
            this.$message.success('保存成功');
            this.innerVisible = false;
            this.updateVisible();
          })
          .catch(() => this.$message.error('提交失败'))
          .complete(() => this.busyLoadingCode = 0);
    }
  }
}
</script>

<style scoped>

.image-avatar {
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}

</style>