<template>
  <div class="page layout-vertical">
    <div class="padding-horizontal-large padding-vertical layout-inflexible font-size-large">
      <back-button @click="$router.back()"></back-button>
      <span class="margin-left">账号信息</span>
    </div>
    <div class="layout-flexible padding-horizontal-large" style="overflow: hidden" v-if="account">
      <ys-infinite-scroll-view style="height: 100%;">
        <div class="layout-vertical layout-center">
          <div class="account-info-panel card padding padding-large margin-top   ">
            <div class="font-weight-bold">账号信息</div>
            <div class="divider margin-vertical"></div>
            <div class="padding-vertical">
              <span>账号：</span>
              <span class="font-size-medium font-weight-bold">{{ account }}</span>
            </div>
            <div>
              <span>头像：</span>
              <ys-image-picker
                  class="image-avatar"
                  :image.sync="avatar"
                  @update:image="handleAvatarInput"
              ></ys-image-picker>
            </div>
            <div class="padding-vertical">
              <span>真实姓名：</span>
              <ys-click-to-edit
                  single-click-to-edit
                  v-model="name"
                  :maxlength="20"
                  ref="inputName"
                  class="font-size-large"
                  @blur="handleNameInput"
              ></ys-click-to-edit>
              <span class="fas fa-edit margin-left font-color-primary"
                    @click="$refs.inputName.focus()"
              ></span>
            </div>
            <div class="padding-vertical">
              <span>昵称：</span>
              <ys-click-to-edit
                  single-click-to-edit
                  v-model="nickName"
                  :maxlength="20"
                  ref="inputNickName"
                  class="font-size-large"
                  @blur="handleNickNameInput"
              ></ys-click-to-edit>
              <span class="fas fa-edit margin-left font-color-primary"
                    @click="$refs.inputNickName.focus()">
              </span>
            </div>
            <div class="padding-vertical">
              <span>联系电话：</span>
              <ys-click-to-edit
                  single-click-to-edit
                  v-model="phone"
                  :maxlength="20"
                  placeholder="请输入手机号码"
                  ref="inputPhone"
                  class="font-size-large"
                  @blur="handlePhoneInput"
              >
              </ys-click-to-edit>
              <span class="fa fa-edit margin-left font-color-primary"
                    @click="$refs.inputPhone.focus()"
              ></span>
            </div>
            <div class="padding-vertical">
              <span>密码：</span>
              <ys-button size="small" type="text" class="font-color-primary"
                  @click="clickResetPassword">重置密码<i
                  class="fas fa-edit margin-left-small"></i></ys-button>
            </div>
          </div>
          <div class="dietitian-info-panel card padding padding-large margin-top">
            <div class="font-weight-bold">营养师</div>
            <div class="divider margin-vertical"></div>
            <ys-button type="secondary" lighting v-if="!withDietitian"
                       @click="clickAddDietitian"
            >添加营养师信息
            </ys-button>
            <template v-if="dietitian">
              <div class="padding-vertical">
                <span>营养师头像：</span>
                <ys-image-picker
                    class="image-dietitian-avatar"
                    style="vertical-align: middle; display: inline-block;"
                    :image.sync="dietitian.avatar"
                    @update:image="handleDietitianAvatarInput"
                ></ys-image-picker>
              </div>
              <div class="padding-vertical">
                <span>营养师姓名：</span>
                <ys-click-to-edit
                    ref="inputDietitianName"
                    single-click-to-edit
                    v-model="dietitian.name"
                    :max-length="20"
                    class="font-size-large"
                    @blur="handleDietitianNameInput"
                ></ys-click-to-edit>
                <span class="fas fa-edit margin-left font-color-primary"
                      @click="$refs.inputDietitianName.focus()"
                ></span>
              </div>
              <div class="padding-vertical ">
                <span>营养师头衔：</span>
                <ys-click-to-edit
                    ref="inputDietitianIntroduction"
                    single-click-to-edit
                    v-model="dietitian.introduction"
                    :max-length="32"
                    class="font-size-large"
                    @blur="handleDietitianIntroductionInput"
                ></ys-click-to-edit>
              </div>
              <div class="padding-vertical layout-horizontal">
                <span class="layout-inflexible">营养师介绍：</span>
                <div class="layout-flexible">
                  <ys-click-to-edit
                      type="textarea"
                      single-click-to-edit
                      v-model="dietitian.message"
                      style="width: 100%;"
                      :maxlength="60"
                      class="font-size-large"
                      @blur="handleDietitianMessageInput"
                  ></ys-click-to-edit>
                </div>
              </div>


            </template>
          </div>
        </div>
      </ys-infinite-scroll-view>
    </div>
    <dietitian-add-dialog
        :visible.sync="dietitianAddDialogVisible"
        :account-id="accountId"
        :account-name="name"
        :account-avatar="avatar"
        @confirm="confirmDietitianAdded"
    ></dietitian-add-dialog>
    <password-reset-dialog
        :account-id="accountId"
        :visible.sync="passwordResetDialogVisible">
    </password-reset-dialog>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import AutosubmitHelper from "@/assets/javascript/autosubmit-helper";
import BackButton from "@/components/backbutton/BackButton";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import YsButton from "@/components/wedigets/YsButton";
import DietitianAddDialog from "@/pages/system/dialog/DietitianAddDialog";
import PasswordResetDialog from "@/pages/system/dialog/PasswordResetDialog";
import YsImagePicker from "@/components/wedigets/YsImagePicker";

const TAG_SET_AVATAR = "set_avatar";

const TAG_SET_NAME = "set_name";

const TAG_SET_NICK_NAME = "set_nick_name";

const TAG_SET_PHONE = "set_phone";

const TAG_SET_DIETITIAN_AVATAR = "set_dietitian_avatar";

const TAG_SET_DIETITIAN_NAME = "set_dietitian_name";

const TAG_SET_DIETITIAN_INTRODUCTION = "set_dietitian_introduction";

const TAG_SET_DIETITIAN_MESSAGE = "set_dietitian_message";

export default {
  name: "Account",
  components: {
    PasswordResetDialog,
    DietitianAddDialog, YsButton, YsImagePicker, YsClickToEdit, YsInfiniteScrollView, BackButton
  },
  mixins: [httpapi, AutosubmitHelper],
  data() {
    return {

      /**
       * 账号信息
       */
      accountId: null,
      account: null,
      accountType: null,
      name: null,
      nickName: null,
      phone: null,
      avatar: null,

      /**
       * 重置密码
       */
      passwordResetDialogVisible: false,

      /**
       * 营养师信息
       */
      withDietitian: null,
      dietitian: null,
      dietitianAddDialogVisible: false,

    }
  },
  activated() {
    this.loadingCode++;
    this.accountId = this.$route.query.id;
    this.dietitian = null;
    this.loadAccount();
  },
  methods: {
    handleAvatarInput() {
      this.createTask({
        tag: TAG_SET_AVATAR,
        params: {
          id: this.accountId,
          avatar: this.avatar,
        },
        runFunc: this.setAccountAvatar,
      })
    },
    handleNameInput() {
      this.createTask({
        tag: TAG_SET_NAME,
        params: {
          id: this.accountId,
          name: this.name,
        },
        runFunc: this.setAccountName,
      })
    },
    handleNickNameInput() {
      this.createTask({
        tag: TAG_SET_NICK_NAME,
        params: {
          id: this.accountId,
          nickName: this.nickName,
        },
        runFunc: this.setAccountNickName,
      })
    },
    handlePhoneInput() {
      this.createTask({
        tag: TAG_SET_PHONE,
        params: {
          id: this.accountId,
          phone: this.phone,
        },
        runFunc: this.setAccountPhone,
      })
    },
    clickResetPassword() {
      this.passwordResetDialogVisible = true;
    },
    handleDietitianAvatarInput() {
      this.createTask({
        tag: TAG_SET_DIETITIAN_AVATAR,
        params: this.dietitian,
        runFunc: this.setDietitianAvatar,
      });
    },
    handleDietitianNameInput() {
      this.createTask({
        tag: TAG_SET_DIETITIAN_NAME,
        params: this.dietitian,
        runFunc: this.setDietitianName,
      })
    },
    handleDietitianIntroductionInput() {
      this.createTask({
        tag: TAG_SET_DIETITIAN_INTRODUCTION,
        params: this.dietitian,
        runFunc: this.setDietitianIntroduction,
      })
    },
    handleDietitianMessageInput() {
      this.createTask({
        tag: TAG_SET_DIETITIAN_MESSAGE,
        params: this.dietitian,
        runFunc: this.setDietitianMessage,
      })
    },
    clickAddDietitian() {
      this.dietitianAddDialogVisible = true;
    },
    confirmDietitianAdded(dietitian) {
      this.withDietitian = true;
      this.dietitian = dietitian;
    },
    loadAccount() {
      this.$reqGet({
        path: `/account/with_private_info/${this.accountId}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let account = res.data;
            this.account = account.account;
            this.accountType = account.accountType;
            this.name = account.name;
            this.nickName = account.nickName;
            this.avatar = account.avatar;
            this.phone = account.phone?.phone;
            this.withDietitian = account.dietitian;
            if (this.withDietitian) {
              this.loadDietitian();
            }
          })
          .catch(() => this.this.$message.error('加载失败'))
    },
    loadDietitian() {
      this.$reqGet({
        path: `/dietitian/with_private_info/account/${this.accountId}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dietitian = res.data;
            this.dietitian = dietitian;
          })
          .catch(() => this.$message.error('加载失败'));
    },
    async setAccountAvatar({id, avatar}) {
      await this.$reqPut({
        path: `/account/${id}/avatar/${avatar?.id}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            window.eventBus.$emit('update:account', {id});
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    },
    async setAccountName({id, name}) {
      await this.$reqPut({
        path: `/account/${id}/name/${name ? encodeURIComponent(name) : ''}`,
      })
          .returnWhenCodeMatches()
          .then(() => {
            window.eventBus.$emit('update:account', {id});
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    },
    async setAccountNickName({id, nickName}) {
      await this.$reqPut({
        path: `/account/${id}/nick_name/${nickName ? encodeURIComponent(nickName) : ''}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            window.eventBus.$emit('update:account', {id});
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    },
    async setAccountPhone({id, phone}) {
      await this.$reqPut({
        path: `/account/${id}/phone/${phone ? encodeURIComponent(JSON.stringify({phone})) : ''}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            window.eventBus.$emit('update:account', {id})
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    },
    async setDietitianAvatar(dietitian) {
      if (!dietitian.avatar) return;
      await this.$reqPut({
        path: `/dietitian/${dietitian.id}/avatar/${dietitian.avatar.id}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            window.eventBus.$emit('update:account', {id: this.accountId});
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    },
    async setDietitianName(dietitian) {
      if (!dietitian.name) return;
      await this.$reqPut({
        path: `/dietitian/${dietitian.id}/name/${encodeURIComponent(dietitian.name)}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            window.eventBus.$emit('update:account', {id: this.accountId});
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    },
    async setDietitianIntroduction(dietitian) {
      await this.$reqPut({
        path: `/dietitian/${dietitian.id}/introduction/${dietitian.introduction ? encodeURIComponent(dietitian.introduction) : ''}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            window.eventBus.$emit('update:account', {id: this.accountId});
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    },
    async setDietitianMessage(dietitian) {
      await this.$reqPut({
        path: `/dietitian/${dietitian.id}/message/${dietitian.message ? encodeURIComponent(dietitian.message) : ''}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            window.eventBus.$emit('update:account', {id: this.accountId});
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    }
  }
}
</script>

<style scoped>

.page {
  height: 100vh;
}

.account-info-panel, .dietitian-info-panel {
  width: 600px;
}

.image-avatar {
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.image-dietitian-avatar {
  width: 88px;
  height: 108px;
}

</style>