<template>
  <ys-dialog
      title="重置密码"
      :visible.sync="innerVisible"
      size="small"
      @update:visible="updateVisible"
  >
    <div class="layout-vertical layout-middle padding padding-large">
      <div class="layout-horizontal layout-middle">
        <ys-input
            show-password
            v-model="password"
            :maxlength="20"
            placeholder="请输入6-20位密码"
        ></ys-input>
      </div>
    </div>
    <div slot="footer">
      <ys-button type="secondary" lighting @click="clickCancel">取消</ys-button>
      <ys-button class="margin-left" lighting @click="clickConfirm">确定</ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import httpapi from "@/assets/javascript/httpapi";
import YsInput from "@/components/wedigets/YsInput";
import YsButton from "@/components/wedigets/YsButton";
import md5 from "js-md5";

export default {
  name: "PasswordResetDialog",
  mixins: [httpapi],
  components: {YsButton, YsInput, YsDialog},
  props: {
    accountId: Number,
    visible: Boolean,
  },
  data() {
    return {

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 密码
       */
      password: null,

      /**
       * 弹窗显示
       */
      innerVisible: false,

    }
  },
  watch: {
    visible: {
      handler(visible) {
        this.innerVisible = visible;
        this.password = null;
      },
      immediate: true,
    },
    accountId: {
      handler() {
        this.loadingCode++;
      },
      immediate: true,
    }
  },
  methods: {
    clickCancel() {
      this.innerVisible = false;
      this.updateVisible();
    },
    clickConfirm() {
      if (!this.password) {
        this.$message.warning('请输入密码');
        return;
      }
      if (this.password.length < 6) {
        this.$message.warning('密码至少6位数');
        return;
      }
      this.resetPassword();
    },
    updateVisible() {
      this.$emit('update:visible', this.innerVisible);
    },
    resetPassword() {
      if (this.busyLoadingCode === this.loadingCode) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      let password = md5(this.password);
      this.$reqPut({
        path: `/account/${this.accountId}/password/${encodeURIComponent(password)}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            this.$message.success('设置成功');
            this.innerVisible = false;
            this.updateVisible();
          })
          .catch(() => this.$message.error('设置失败'))
          .complete(() => this.busyLoadingCode = 0);
    }
  }
}
</script>

<style scoped>

</style>